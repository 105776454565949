import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "components/seo";
import Layout from "components/layout";
const HowItWorks = ({ data }) => {
  return (
    <React.Fragment>
      <Layout>
        <Seo
          title="How it works"
          description="Best for vehicle inspection report, rental inspection checklist, house inspection checklist and inspection report template. Try it FREE!"
        />
        <div className="mt-32 sir-container mb-32">
          <div className="text-center space-y-3">
            <h1 className="text-4xl font-bold">How It Works</h1>
            <p>Learn from our videos</p>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            {data.allHowItWorksJson.edges.map(({ node }) => (
              <div key={node.id} className="border rounded-lg border-solid p-3">
                <Link
                  to={`/${node.slug}`}
                  className="text-sir-secondary"
                  alt={node.name}
                >
                  <div className="rounded-lg overflow-hidden">
                    <GatsbyImage image={getImage(node.image)} alt={node.name} />
                  </div>
                </Link>
                <div className="p-5 space-y-3">
                  <h2 className="text-xl font-bold">{node.name}</h2>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{ __html: node.description }}
                      className="line-clamp-3"
                    />
                  </p>
                  <div>
                    <Link
                      to={`/${node.slug}`}
                      className="text-sir-secondary"
                      alt={node.name}
                    >
                      {node.link.text}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default HowItWorks;

export const query = graphql`
  {
    allHowItWorksJson {
      edges {
        node {
          id
          slug
          name
          description
          link {
            href
            text
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 50)
            }
          }
        }
      }
    }
  }
`;
